<template>
  <v-app v-if="userData" id="inspire">
    <v-app-bar app color="primary">
      <!-- Logo -->
      <v-img
        alt="Logo"
        class="shrink mr-2"
        contain
        src="@/assets/logo.png"
        transition="scale-transition"
        width="40"
      />
      <v-toolbar-title>Honex</v-toolbar-title>

      <v-spacer></v-spacer>

      <!-- Gravatar image -->
      <v-avatar>
        <img :src="getGravatar(userData.email)" alt="Avatar" />
      </v-avatar>
    </v-app-bar>

    <v-main>
      <v-container>
        <v-row>
          <v-col
            v-for="(app, index) in apps"
            :key="index"
            cols="4"
            sm="6"
            md="6"
            lg="4"
            xl="2"
            xxl="2"
          >
            <v-card height="250" @click="openApp(app)">
              <v-card-title class="justify-center">{{ app.name }}</v-card-title>
              <v-card-text class="text-center card">
                <div class="card-image">
                  <v-img
                    :src="app.img"
                    contain
                    max-height="100"
                    max-width="100"
                    :alt="app.name"
                    transition="scale-transition"
                  ></v-img>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { jwtDecode } from "jwt-decode";
import gravatar from "gravatar";
import apps from "@/apps.json";
export default {
  name: "AppHome",
  data() {
    return {
      userData: null,
      apps: apps,
    };
  },
  methods: {
    getGravatar(email) {
      return gravatar.url(email, { s: "100", r: "x", d: "retro" });
    },
    openApp(app) {
      window.open(app.url, "_blank");
    },
  },
  mounted() {
    //Read user data from cookie CF_Authorization JWT
    this.userData = jwtDecode(this.$cookies.get("CF_Authorization"));
  },
};
</script>

<style lang="scss" scoped>
.card {
  display: flex;
  justify-content: center;
  align-items: center;
  .card-image {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #8f54a0;
    border-radius: 50%;
    padding: 25px;
  }
}
</style>

<style lang="scss">
/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: auto;
  scrollbar-color: #591769 #121212;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 16px;
}

*::-webkit-scrollbar-track {
  background: #121212;
}

*::-webkit-scrollbar-thumb {
  background-color: #591769;
  border-radius: 10px;
  border: 3px solid #121212;
}
</style>
